import * as React from "react";

const SimplePracticeLogoBlue: React.FC<{ [key: string]: any }> = ({
  ...props
}) => (
  <svg {...props} width="126.29" height="24">
    <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
  </svg>
);

export default SimplePracticeLogoBlue;
