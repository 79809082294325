import * as React from "react";
import { graphql, PageProps } from "gatsby";
import TrialSignUpLayout from "../../layouts/TrialSignUpLayout";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import { TestimonialSliderPType } from "@types";
import TrialSignUpFormContainer from "../../containers/TrialSignUpFormContainer";
import TrialSignupHeader from "../../components/Headers/TrialSignupHeader";
import { ImageMediaItemUrl, YoastSEOType } from "@types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useSetPromocode from "../../hooks/useSetPromocode";
import { mxpSignupFirstView } from "../../utils/mixpanelEvents/mixpanelEvents";
import { useEffect } from "react";
import { deIndexSeo } from "../../utils/deIndexSeo";

type TrialSignUpPageProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      trialSignUpHeroP: {
        trialSignUpPageLogo: ImageMediaItemUrl;
        asidePageSubtitle: string;
        asidePageTitle: string;
        microcopy: string;
        microcopyColor: string;
        benefitsList: Array<{ benefit: string }>;
      };
      partnerPromoPg: {
        partnerPromo: string;
      };
      testimonialSliderP: TestimonialSliderPType;
    };
  };
};

const TrialSignUpPage: React.FC<PageProps<TrialSignUpPageProps>> = ({
  data,
}) => {
  const {
    wpPage: {
      seo,
      template: {
        trialSignUpHeroP,
        testimonialSliderP,
        partnerPromoPg: { partnerPromo },
      },
    },
  } = data;
  useSetPromocode(partnerPromo);
  const asideData = {
    asidePageTitle: trialSignUpHeroP.asidePageTitle,
    asidePageSubtitle: trialSignUpHeroP.asidePageSubtitle,
    microcopy: trialSignUpHeroP.microcopy,
    microcopyColor: trialSignUpHeroP.microcopyColor,
    benefitsList: trialSignUpHeroP.benefitsList,
  };

  testimonialSliderP.bWhite = true;

  useEffect(() => {
    mxpSignupFirstView("TESTGROUP_ID");
  });

  return (
    <TrialSignUpLayout>
      {seo && (
        <SEO post={deIndexSeo(data.wpPage as unknown as Queries.WpPage)} />
      )}
      <TrialSignupHeader pageLogo={trialSignUpHeroP.trialSignUpPageLogo} />
      <TrialSignUpFormContainer asidePageData={asideData} />
      <TestimonialSliderPContainer props={testimonialSliderP} isPadBottom64 />
    </TrialSignUpLayout>
  );
};

export default TrialSignUpPage;

export const query = graphql`
  query TrialSignUpTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        templateName
        ... on WpTrialSignUpTemplate {
          templateName
          trialSignUpHeroP {
            asidePageTitle: trialSignUpHeroTitle
            asidePageSubtitle: trialSignUpHeroSubcopy
            microcopy: trialSignUpHeroFooterCopy
            microcopyColor: trialSignUpHeroFooterCopyColor
            trialSignUpPageLogo {
              altText
              mediaItemUrl
              localFile {
                publicURL
              }
            }
            benefitsList: trialSignUpHeroBenefitsList {
              benefit
            }
          }
          partnerPromoPg {
            partnerPromo
          }
          testimonialSliderP {
            testimonialHeadline
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                testimonialsC {
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                  testimonialVideoBackgroundImage {
                    altText
                    mediaItemUrl
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
