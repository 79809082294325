import React from "react";
import CustomerSatisfactionBanner from "../../components/Banners/CustomerSatisfactionBanner/CustomerSatisfactionBanner";
import BlackCheckmark from "../../components/SVGIcons/BlackCheckmark/BlackCheckmark";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { trialSignupAsideContent } from "@types";

import styles from "./TrialSignUpFormContainer.module.scss";

type TrialSignUpFormContainerProps = {
  asidePageData: trialSignupAsideContent;
};

const TrialSignUpFormContainer: React.FC<TrialSignUpFormContainerProps> = ({
  asidePageData,
}) => {
  const {
    microcopy,
    microcopyColor,
    asidePageSubtitle,
    asidePageTitle,
    benefitsList,
  } = asidePageData;

  let benefits = benefitsList?.map((item, index) => (
    <div key={index} className={`${styles.featureRow} pad-bottom--16`}>
      <BlackCheckmark cls={styles.icon} />
      {item.benefit}
    </div>
  ));

  return (
    <section
      data-testid="section:FormContainer"
      className={`pad-top--large-sans-header pad-bottom--large pos--relative bluePaleBg`}
    >
      <div className="full-width">
        <div className={styles.gridFeatures}>
          <div className={styles.heroTopAlign}>
            <h1 className={styles.asideTitle}>{asidePageTitle}</h1>

            <div className="pad-top--8">
              <p
                className={`pad-top--8 ${styles.asideSubtitle}`}
                dangerouslySetInnerHTML={{ __html: asidePageSubtitle }}
              />
            </div>

            <div className={styles.benefitsContainer}>
              {benefits}
              <div
                className={styles.asideMicrocopy}
                style={{ color: microcopyColor }}
              >
                {microcopy}
              </div>
            </div>
          </div>

          <SignUpForm page={"trialSignUp"} signUpTitle={""} />
        </div>
      </div>

      <CustomerSatisfactionBanner />
    </section>
  );
};

export default TrialSignUpFormContainer;
